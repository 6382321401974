// Welcome to Code in Framer
// Get Started: https://www.framer.com/developers/
import { addPropertyControls, ControlType, Frame, Data, Image } from "framer"
import React from "react"
import MultiSelectDropDown from "./MultiSelectDropDown.tsx"
import SingleSelectDropDown from "./SingleSelectDropdown.tsx"
import LoadingSpinner from "./LoadingSpinner.tsx"
import ColorBadges from "./ColorBadges.tsx"
import AusstellerAccordion from "./AusstellerAccordion.tsx"
import { useFetch } from "./useFetch.tsx"
import AusstellerHeader from "./AusstellerHeader.tsx"
import usePageable from "./usePageable.tsx"
import SearchInput from "./SearchInput.tsx"
import Fuse from "https://cdn.jsdelivr.net/npm/fuse.js@7.0.0/dist/fuse.mjs"
export type ColumnType = "text" | "colorBadge" | "filter" | "multifilter"

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/#code-components-auto-sizing
 *
 * @framerSupportedLayoutWidth any-prefer-fixed
 * @framerSupportedLayoutHeight auto
 */
export default function AusstellerTabelle(props) {
    const [search, setSearch] = React.useState("")
    const colorBadges: string[] = props.colorBadges.map(
        (colorBadge) => colorBadge.name
    )
    const [selectedDays, setSelectedDays] = React.useState<string[]>([])
    const [selectedColorBadges, setSelectedColorBadges] =
        React.useState<string[]>(colorBadges)

    const { data: availableDays, isLoading: isLoadingDays } = useFetch<string>(
        `https://maxkrass-bondingkataloggateway.web.val.run/available-days/${props.event_name}`
    )
    const searchUrl = `https://maxkrass-bondingkataloggateway.web.val.run/exhibitors/${props.event_name}?days=${selectedDays.join(",")}`
    const {
        data,
        loading: isLoadingExhibitors,
        loadMore,
        hasMore,
    } = usePageable<{
        id: string
        fields: Record<string, string>
    }>(searchUrl, {
        pageSize: props.pageSize,
    })

    const exhibitors = search.length > 2 ? getSearchedExhibitors(data) : data

    function getSearchedExhibitors(data) {
        const fuse = new Fuse(data, {
            keys: Object.keys(data[0].fields ?? {}).map(
                (key) => "fields." + key
            ),
            minMatchCharLength: 3,
        })

        return fuse.search(search).map((result) => result.item)
    }

    function cellRenderer({
        cell,
    }: {
        cell: { columnType: ColumnType; data: any }
    }): React.ReactNode {
        switch (cell.columnType) {
            case "text":
                return <>{cell.data}</>
            case "colorBadge":
                return (
                    <>
                        <ColorBadges
                            colorBadges={props.colorBadges}
                            activeColorBadges={cell.data}
                        />
                    </>
                )
            case "multifilter":
                return <>{cell.data.join(" & ")}</>
        }
    }

    React.useEffect(() => {
        if (availableDays && availableDays.length > 0) {
            setSelectedDays(availableDays)
        }
    }, [availableDays])

    return (
        <div
            style={{
                fontFamily: "Poppins",
                display: "flex",
                flexDirection: "column",
                rowGap: "2em",
                alignItems: "flex-start",
            }}
        >
            {isLoadingDays ? (
                <div
                    style={{
                        gridColumn: "span 4",
                    }}
                >
                    <LoadingSpinner />
                </div>
            ) : (
                <div
                    style={{
                        display: "flex",
                        gap: "1em",
                        justifyContent: "center",
                        flexDirection: "row",
                        flexWrap: "wrap",
                    }}
                >
                    <SearchInput
                        search={search}
                        setSearch={setSearch}
                        placeholder="Suche"
                    />
                    <MultiSelectDropDown
                        options={availableDays ?? []}
                        allValuePlaceholder="Alle Tage"
                        value={selectedDays}
                        onChange={(value) => setSelectedDays(value)}
                        placeholder={"Select the days"}
                    />
                    {!props.hideColorBadges ? (
                        <MultiSelectDropDown
                            options={colorBadges ?? []}
                            allValuePlaceholder="Alle Fachrichtungen"
                            value={selectedColorBadges}
                            onChange={(value) => setSelectedColorBadges(value)}
                            placeholder={"Select the color badges"}
                        />
                    ) : null}
                </div>
            )}

            {exhibitors && exhibitors.length > 0 ? (
                <>
                    <div
                        style={{
                            overflowX: "scroll",
                            width: "100%",
                        }}
                    >
                        <div
                            style={{
                                padding: "1em",
                                color: "rgba(0,0,0,0.9)",
                                borderRadius: "1em",
                                fontSize: "1em",
                                display: "grid",
                                columnGap: "2em",
                                rowGap: "0.5em",
                                width: "fit-content",
                                background: "#E9EEFB",
                                gridTemplateColumns: `repeat(${props.columns.length}, auto)`,
                                //gridAutoColumns: "minmax(8em, auto)",
                                //gridAutoFlow: "column",
                            }}
                        >
                            <AusstellerHeader
                                headers={props.columns
                                    .map((column) => column.header)
                                    .filter(Boolean)}
                            />
                            {exhibitors.map((exhibitor, index) => (
                                <AusstellerAccordion
                                    exhibitor={exhibitor}
                                    cellRenderer={cellRenderer}
                                    columns={props.columns}
                                    key={index}
                                    expandable={props.beta.enableAccordion}
                                    logoColumn={props.beta.logoColumn}
                                    imageColumn={props.beta.imageColumn}
                                    nameColumn={props.columns[0].accessorKey}
                                />
                            ))}
                        </div>
                    </div>
                    {hasMore && (
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <button
                                style={{
                                    background: "#007ec6",
                                    fontFamily: "Poppins",
                                    color: "white",
                                    border: "none",
                                    borderRadius: "0.5em",
                                    paddingInline: "1em",
                                    paddingBlock: "0.5em",
                                    display: "inline-grid",
                                    gridTemplateColumns: "auto auto",
                                    alignItems: "center",
                                    columnGap: "1em",
                                    transition: "0.3s all",
                                }}
                                onClick={loadMore}
                                disabled={isLoadingExhibitors}
                            >
                                {isLoadingExhibitors ? (
                                    <>
                                        <LoadingSpinner />
                                        <span>Load more</span>
                                    </>
                                ) : (
                                    <span
                                        style={{
                                            gridColumn: "span 2",
                                        }}
                                    >
                                        Load more
                                    </span>
                                )}
                            </button>
                        </div>
                    )}
                </>
            ) : isLoadingExhibitors ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "50dvh",
                    }}
                >
                    <LoadingSpinner />
                </div>
            ) : (
                <p>Keine Aussteller gefunden!</p>
            )}
        </div>
    )
}

AusstellerTabelle.defaultProps = {
    colorBadges: [
        {
            name: "Maschinenbau",
        },
    ],
    columns: [
        {
            header: "Aussteller",
            accessorKey: "Unternehmen",
            columnType: "text",
        },
        {
            header: "Branche",
            columnType: "text",
        },
        {
            header: "Gesuchte Fachrichtung",
            accessorKey: "ColorBadges",
            columnType: "colorBadge",
        },
        {
            header: "Auftrittstag",
            columnType: "text",
        },
        {
            header: "Ausstellerart",
            columnType: "text",
        },
    ],
    pageSize: 10,
}

// Styles are written in object syntax
// Learn more: https://reactjs.org/docs/dom-elements.html#style
const containerStyle = {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
}

addPropertyControls(AusstellerTabelle, {
    event_name: {
        type: ControlType.String,
        defaultValue: "Messe Aachen 2024",
    },
    hideColorBadges: {
        type: ControlType.Boolean,
    },
    colorBadges: {
        type: ControlType.Array,
        hidden: (props) => props.hideColorBadges,
        control: {
            type: ControlType.Object,
            controls: {
                name: {
                    type: ControlType.String,
                },
                icon: {
                    type: ControlType.ResponsiveImage,
                },
            },
        },
        maxCount: 10,
    },
    columns: {
        type: ControlType.Array,
        maxCount: 7,
        title: "Spalten",
        control: {
            type: ControlType.Object,
            icon: "object",
            controls: {
                header: {
                    type: ControlType.String,
                    title: "Überschrift",
                },
                accessorKey: {
                    type: ControlType.String,
                    title: "Spaltenname",
                    description: "Spaltenname aus SharePoint",
                },
                columnType: {
                    type: ControlType.Enum,
                    options: ["text", "colorBadge", "filter", "multifilter"],
                    defaultValue: "text",
                    displaySegmentedControl: true,
                    segmentedControlDirection: "vertical",
                    optionTitles: [
                        "Text",
                        "ColorBadges",
                        "Filterbarer Text",
                        "Filterbarer Text (Mehrfachauswahl)",
                    ],
                },
            },
        },
    },
    pageSize: {
        description:
            "Wie viele Elemente sollen angezeigt und nachgeladen werden?",
        title: "Zeilenanzahl",
        type: ControlType.Enum,
        displaySegmentedControl: true,
        segmentedControlDirection: "horizontal",
        options: [10, 25, 100],
        optionTitles: ["10", "25", "100"],
    },
    beta: {
        type: ControlType.Object,
        title: "Experimentell",
        buttonTitle: "Erweiterte Einstellungen",
        controls: {
            enableAccordion: {
                type: ControlType.Boolean,
                defaultValue: false,
                title: "Ausklappbare Zeilen (beta)",
                description: "Sollen die Zeilen ausklappbar sein?",
            },
            logoColumn: {
                hidden: (props) => props.beta.enableAccordion,
                type: ControlType.String,
                defaultValue: "@Logo",
                title: "Logo-Spalte",
                description: "Name der Logo-Spalte",
            },
            imageColumn: {
                hidden: (props) => props.beta.enableAccordion,
                type: ControlType.String,
                defaultValue: "@Imageanzeige",
                title: "Anzeige-Spalte",
                description: "Name der Anzeige-Spalte",
            },
        },
    },
})
